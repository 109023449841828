import React from 'react';

const LogoMobile = () => {
  return (
    <svg
      width="29"
      height="33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="logoMobile"
    >
      <path
        d="M16.902 0A17.12 17.12 0 004.94 4.757a16.39 16.39 0 00-3.61 5.19 15.878 15.878 0 000 12.703 16.651 16.651 0 008.97 8.754 16.97 16.97 0 006.602 1.26 16.648 16.648 0 006.704-1.359 18.67 18.67 0 004.703-2.985l-6.351-6.351a8.059 8.059 0 01-5.081 1.877 7.717 7.717 0 01-3.042-.596 8.192 8.192 0 01-2.47-1.606 7.186 7.186 0 01-1.658-2.392 7.374 7.374 0 01-.596-2.96 7.254 7.254 0 01.617-2.883 7.53 7.53 0 011.659-2.42 7.938 7.938 0 012.47-1.631c.963-.407 1.999-.613 3.045-.607 1.87.02 3.674.7 5.091 1.923l6.352-6.351a17.843 17.843 0 00-4.647-2.936A16.936 16.936 0 0016.902 0z"
        fill="#2A348A"
      />
      <path
        d="M10.3 1.317a17.102 17.102 0 00-5.36 3.479c-.44.426-.854.876-1.243 1.348a5.903 5.903 0 003.091 3.56l4.04 1.94c.175-.214.361-.42.558-.613a7.94 7.94 0 012.47-1.63 7.741 7.741 0 017.667.963c.025-.05.053-.092.074-.138a13.536 13.536 0 00.904-9.259C20.7.348 18.808.034 16.904.04A17.042 17.042 0 0010.3 1.317z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M9.732 31.178a5.889 5.889 0 001.09-3.656l-.268-6.781a7.562 7.562 0 01-.826-1.437 7.41 7.41 0 01-.596-2.963v-.05a5.905 5.905 0 00-4.767-2.117L.13 14.34A16.232 16.232 0 000 16.344a15.494 15.494 0 001.33 6.351 16.584 16.584 0 008.4 8.469v.014z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M23.616 31.348a18.607 18.607 0 004.704-2.985l-6.352-6.351a8.03 8.03 0 01-5.077 1.88c-.198 0-.388 0-.579-.028-.26 3.093.55 6.183 2.294 8.75a16.33 16.33 0 005.01-1.266z"
        fill="url(#paint2_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="3.704"
          y1="5.837"
          x2="22.935"
          y2="5.837"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".21" stop-color="#2A348A" />
          <stop offset="1" stop-color="#7E89C1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="10.886"
          y1="19.326"
          x2="2.174"
          y2="24.354"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2A348A" />
          <stop offset=".22" stop-color="#2E388B" />
          <stop offset="1" stop-color="#7E89C1" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="24.83"
          y1="31.852"
          x2="16.587"
          y2="23.61"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".1" stop-color="#2A348A" />
          <stop offset="1" stop-color="#7E89C1" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LogoMobile;
