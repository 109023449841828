import React, { useState, useEffect } from "react";
import Map from "../components/Map/Map";
import SoonestAppointments from "../components/SoonestAppointments/SoonestAppointments";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import DateSelector from "../components/DateSelector/DateSelector";
import Footer from "../components/Footer/Footer";
import routes from "../routes/routes";
import clinics from "../data/parks.json";
import {
  getSymptomsList,
  getNextThreeDays,
  setNextThreeDays,
  resetDateAndTime,
  receiveCheckIfStillAvailable,
} from "../actions/covidActions";
import moment from "moment";
import momentBusinessDays from "moment-business-days";
import { useHistory, Redirect } from "react-router-dom";

const SoonestPage: React.FC = () => {
  const [displayedComponent, setDisplayedComponent] = useState<string>(
    "dateSelector"
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedClinic = useSelector(
    (state: RootStateOrAny) => state.mainReducer.selectedClinic
  );

  useEffect(() => {
    dispatch(receiveCheckIfStillAvailable());
  }, []);

  const date = useSelector((state: RootStateOrAny) => state.mainReducer.date);
  const time = useSelector((state: RootStateOrAny) => state.mainReducer.time);

  return (
    <>
      <div className="options">
        <div className="container">
          <div className="options__content">
            <div className="options__clinicInfo">
              <div className="options__map">
                <Map chosenClinic={selectedClinic} size="sm" />
              </div>
              <div>
                <p>
                  {selectedClinic.address}, {selectedClinic.city}
                </p>
                <h2 style={{ marginBottom: "30px" }}>{selectedClinic.name}</h2>
                <p style={{ marginBottom: "10px" }}>
                  {selectedClinic.description}
                </p>
              </div>
            </div>

            <div className="options__appointmentInfo">
              <div className="options__nav">
                <button
                  type="button"
                  className={displayedComponent === "soonest" ? "active" : ""}
                  onClick={() => setDisplayedComponent("soonest")}
                >
                  Soonest
                </button>
                <button
                  type="button"
                  className={
                    displayedComponent === "dateSelector" ? "active" : ""
                  }
                  onClick={() => setDisplayedComponent("dateSelector")}
                >
                  Choose a date
                </button>
              </div>
              {displayedComponent === "soonest" && <SoonestAppointments />}
              {displayedComponent === "dateSelector" && <DateSelector />}
            </div>
          </div>
        </div>
      </div>
      <Footer
        nextLink={routes.PROFILE_PAGE.path}
        prevLinkTitle="Clinic"
        nextLinkTitle="Set up Profile"
        isAuthorized={false}
        progressLevel={45}
        hideNextStep={!date || !time ? false : true}
      />
    </>
  );
};

export default SoonestPage;
