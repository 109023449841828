import React, { useEffect } from "react";
import Map from "../components/Map/Map";
import clinics from "../data/parks.json";
import s from "../assets/styles/PagesStyles.module.scss";
import fullCheckIcon from "../assets/images/full-check-icon.svg";
import appointmentIcon from "../assets/images/appointments-icon.svg";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";

const ConfirmationPage: React.FC = () => {
  const selectedClinic = useSelector(
    (state: RootStateOrAny) => state.mainReducer.selectedClinic
  );
  const history = useHistory();
  let date = useSelector((state: RootStateOrAny) => state.mainReducer.date);
  date = moment(date).format("YYYY-MM-DD");

  const time = useSelector((state: RootStateOrAny) => state.mainReducer.time);
  const future = moment(time, "hh:mm A").add(30, "minutes").format("hh:mm A");

  useEffect(() => {
    if (!selectedClinic.address) {
      history.push("/");
    }
  }, []);

  return (
    <div className={s.confirmation}>
      <div className="container custom-container">
        <div className={s.confirmation__title}>
          <img src={fullCheckIcon} alt="img" />
          <h2>Appointment Confirmation</h2>
          <p>
            {moment(date).format("LL")} at{" "}
            {moment(time, "hh:mm A").format("LT")}
          </p>
        </div>
        <div className={s.confirmation__container}>
          <div className={s.confirmation__content}>
            <div className={s.confirmation__map}>
              <Map size="md" chosenClinic={selectedClinic} />
            </div>
          </div>
          <div className={s.confirmation__content}>
            <p>
              {selectedClinic.address}, {selectedClinic.city}
            </p>
            <h3>{selectedClinic.name}</h3>
            <a
              href={`https://calendar.google.com/calendar/r/eventedit?text=Covid Appointment at ${
                selectedClinic.name
              }&dates=${moment(`${date} ${time}`, "YYYY-MM-DD hh:mm A").format(
                "YYYYMMDDTHHmmss"
              )}/${moment(`${date} ${future}`, "YYYY-MM-DD hh:mm A").format(
                "YYYYMMDDTHHmmss"
              )}&details=Schedule through Corigan&location=${
                selectedClinic.address
              }`}
              target="_blank"
            >
              <img src={appointmentIcon} alt="img" />
              Add to Calendar
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPage;
