import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import ArrowIcon from "../../assets/icons/ArrowIcon";
import "react-calendar/dist/Calendar.css";
import "./CalendarCustom.scss";
import s from "./DateSelector.module.scss";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import {
  setAppointmentDate,
  setAppointmentTime,
  getTimesFromDate,
} from "../../actions/covidActions";
import moment from "moment";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const DateSelector: React.FC = () => {
  const dispatch = useDispatch();
  const selectedClinic = useSelector(
    (state: RootStateOrAny) => state.mainReducer.selectedClinic
  );
  const times = useSelector((state: RootStateOrAny) => state.mainReducer.times);
  const selectedDate = useSelector(
    (state: RootStateOrAny) => state.mainReducer.date
  );
  const time = useSelector((state: RootStateOrAny) => state.mainReducer.time);
  useEffect(() => {
    var label = document.getElementsByClassName(
      "react-calendar__navigation__label"
    );

    const labelElement = label[0];

    (labelElement as HTMLButtonElement).onclick = null;
    labelElement.addEventListener(
      "click",
      function (e: any) {
        e.preventDefault();
        e.stopPropagation();
        return null;
      },
      true
    );
  }, []);

  // useEffect(() => {}, [selectedDate]);

  const [date, setDate] = useState<any>(null);

  const handleDateChange = (date: any) => {
    if (date !== selectedDate) {
      dispatch(setAppointmentTime(null));
    }
    setDate(date);
    dispatch(
      getTimesFromDate(selectedClinic.pk, moment(date).format("YYYY-MM-DD"))
    );
    dispatch(setAppointmentDate(moment(date).format("YYYY-MM-DD")));
  };

  return (
    <div className="calendar-wrapper">
      <div className={s.calendarWrap}>
        <Calendar
          calendarType="US"
          onChange={handleDateChange}
          value={date}
          navigationLabel={({ date }) => `${months[date.getMonth()]}`}
          minDate={new Date()}
          // tileDisabled={({ activeStartDate, date }) => date.getDay() === 0}
        />
        <ArrowIcon
          direction="left"
          fill="#ffffff"
          className="calendar__icon_left"
        />
        <ArrowIcon
          direction="right"
          fill="#ffffff"
          className="calendar__icon_right"
        />
      </div>
      {date && (
        <div className="soonest-date">
          <h4>Choose a time</h4>
          {times.length != 0 ? (
            times.map((time: string) => (
              <button
                onClick={() =>
                  dispatch(setAppointmentTime(moment(time).format("HH:mm")))
                }
              >
                {moment(time).format("LT")}
              </button>
            ))
          ) : (
            <p className="no-times">
              No available times for this date. Please select another date.
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default DateSelector;
