import React from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";
import routes from "./routes";
import RefreshRoute from "./redirectroute.route";

const Router: React.FC = () => {
  const selectedClinic = useSelector(
    (state: RootStateOrAny) => state.mainReducer.selectedClinic
  );
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path={routes.HOME_PAGE.path}
          component={routes.HOME_PAGE.component}
        />
        <Route
          path={routes.SEARCH_PAGE.path}
          component={routes.SEARCH_PAGE.component}
        />
        <RefreshRoute
          path={routes.OPTIONS_PAGE.path}
          component={routes.OPTIONS_PAGE.component}
          selectedClinic
        />
        <RefreshRoute
          path={routes.PROFILE_PAGE.path}
          component={routes.PROFILE_PAGE.component}
          selectedClinic
        />
        <RefreshRoute
          path={routes.SYMPTOMS_LIST_PAGE.path}
          component={routes.SYMPTOMS_LIST_PAGE.component}
          selectedClinic
        />
        <RefreshRoute
          path={routes.SYMPTOMS_TIMELINE_PAGE.path}
          component={routes.SYMPTOMS_TIMELINE_PAGE.component}
          selectedClinic
        />
        <RefreshRoute
          path={routes.IN_CONTACT_PAGE.path}
          component={routes.IN_CONTACT_PAGE.component}
          selectedClinic
        />
        <RefreshRoute
          path={routes.CONFIRMATION_PAGE.path}
          component={routes.CONFIRMATION_PAGE.component}
          selectedClinic
        />

        <Redirect to={routes.HOME_PAGE.path} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
