import { Dispatch } from "react";

import axios, { AxiosRequestConfig } from "axios";
import config from "../config";
import moment, { Moment } from "moment";
import { profile } from "console";
import * as yup from "yup";
const mbxClient = require("@mapbox/mapbox-sdk");
const mbxGeocoding = require("@mapbox/mapbox-sdk/services/geocoding");

const baseClient = mbxClient({ accessToken: config.MAPBOX_TOKEN });
const geocodingClient = mbxGeocoding(baseClient);

export function setModal(action: boolean) {
  return {
    type: "SET_MODAL",
    modal: action,
  };
}

export function resetDateAndTime() {
  return {
    type: "RESET_DATE_AND_TIME",
    date: null,
    time: null,
  };
}

function requestCheckIfStillAvailable() {
  return {
    type: "REQUEST_CHECK_IF_STILL_AVAILABLE",
    checkingAvailability: true,
  };
}
export function receiveCheckIfStillAvailable() {
  return {
    type: "RECEIVE_CHECK_IF_STILL_AVAILABLE",
    checkingAvailability: false,
    isAvailable: true,
  };
}
function errorCheckIfStillAvailable() {
  return {
    type: "ERROR_CHECK_IF_STILL_AVAILABLE",
    checkingAvailability: false,
    isAvailable: false,
  };
}

function requestCreateUser() {
  return {
    type: "REQUEST_CREATE_USER",
    isCreatingUser: true,
    createUserError: "",
  };
}

function receiveCreateUser(userPk: number) {
  return {
    type: "RECEIVE_CREATE_USER",
    isCreatingUser: false,
    userPk,
    hasCreatedUser: true,
  };
}

function errorCreateUser(err: any) {
  return {
    type: "ERROR_CREATE_USER",
    isCreatingUser: false,
    createUserError: err,
  };
}

function errorSubmittingData(err: any) {
  return {
    type: "ERROR_SUBMITTING_DATA",
    submittingAllDataError: err,
    isSubmittingAllData: false,
  };
}

function requestGetCoordinates() {
  return {
    type: "REQUEST_GET_COORDINATES",
    isFetchingCoordinates: true,
  };
}

export function setNextThreeDays(threeDays: Array<Moment>) {
  return {
    type: "SET_NEXT_THREE_DAYS",
    threeDays,
  };
}

export function receiveFirstTimes(firstTimes: Array<string>) {
  return {
    type: "RECEIVE_FIRST_TIMES",
    firstTimes,
  };
}

export function receiveSecondTimes(secondTimes: Array<string>) {
  return {
    type: "RECEIVE_SECOND_TIMES",
    secondTimes,
  };
}

export function receiveThirdTimes(thirdTimes: Array<string>) {
  return {
    type: "RECEIVE_THIRD_TIMES",
    thirdTimes,
  };
}

export function setProfileData(profileData: object) {
  return {
    type: "SET_PROFILE_DATA",
    profileData,
    profileComplete: true,
  };
}

function receiveIsNewUser(newUser: boolean) {
  return {
    type: "RECEIVE_IS_NEW_USER",
    newUser,
  };
}

function receiveTimes(times: Array<string>) {
  return {
    type: "RECEIVE_TIMES",
    times,
  };
}

export function receiveGetCoordinates(
  coordinates: Array<number>,
  placeName: string
) {
  return {
    type: "RECEIVE_GET_COORDINATES",
    isFetchingCoordinates: false,
    coordinates,
    placeName,
  };
}

function errorGetCoordinates(err: any) {
  return {
    type: "ERROR_GET_COORDINATES",
    getCoordinatesError: err,
  };
}

function requestSearchLocation() {
  return {
    type: "REQUEST_SEARCH_LOCATION",
    isSearchingLocations: true,
  };
}

function receiveSearchLocation(locations: any) {
  return {
    type: "RECEIVE_SEARCH_LOCATION",
    isSearchingLocations: false,
    locations,
  };
}

function errorSearchLocation(searchError: any) {
  return {
    type: "ERROR_SEARCH_LOCATION",
    isSearchingLocations: false,
    searchError,
  };
}

function requestGetClinics() {
  return {
    type: "REQUEST_GET_CLINICS",
    isFetchingClinics: true,
  };
}

function receiveGetClinics(clinics: Array<any>) {
  return {
    type: "RECEIVE_GET_CLINICS",
    isFetchingClinics: false,
    clinics,
  };
}

function requestSymptomsList() {
  return {
    type: "REQUEST_SYMPTOMS_LIST",
    isFetchingSymptoms: true,
  };
}

function receiveSymptomsList(symptoms: any) {
  return {
    type: "RECEIVE_SYMPTOMS_LIST",
    isFetchingSymptoms: false,
    symptoms,
  };
}
function errorSymptomsList() {
  return {
    type: "RECEIVE_SYMPTOMS_LIST",
    isFetchingSymptoms: false,
  };
}

export function setAppointmentTime(time: any) {
  return {
    type: "SET_APPOINTMENT_TIME",
    time,
  };
}

export function getCurrentCoordinates() {
  return async (dispatch: Dispatch<any>) => {
    dispatch(requestGetCoordinates());

    navigator.geolocation.getCurrentPosition(
      (pos) => {
        axios({
          method: "get",
          url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${pos.coords.longitude},${pos.coords.latitude}.json?access_token=pk.eyJ1IjoibGV3aXNtZW5lbGF3cyIsImEiOiJjam1ydW5wangwam9tM2twMmEzZXVpb3ZnIn0.EPCfQNyf5-ibEL-28h7apA`,
        })
          .then((res) => res.data)
          .then((data) => {
            console.log(data);
            dispatch(
              receiveGetCoordinates(
                [pos.coords.latitude, pos.coords.longitude],
                data.features[0].place_name
              )
            );
          })
          .catch((err) => {
            dispatch(errorGetCoordinates(err.response));
          });
      },
      (err) => {
        console.log(err);
      }
    );
  };
}

export function searchLocation(searchQuery: string) {
  return (dispatch: Dispatch<any>) => {
    dispatch(requestSearchLocation());
    geocodingClient
      .forwardGeocode({
        query: searchQuery,
        limit: 5,
      })
      .send()
      .then((response: any) => {
        dispatch(receiveSearchLocation(response.body.features));
      })
      .catch((err: any) => {
        dispatch(errorSearchLocation(err.response));
      });
  };
}

export function getClinics(coordinates: Array<number>) {
  let config: AxiosRequestConfig = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API}/get_closest_office/${coordinates[0]}/${coordinates[1]}/`,
  };

  return (dispatch: Dispatch<any>) => {
    dispatch(requestGetClinics());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetClinics(data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function clinicSelect(selectedClinic: object) {
  return {
    type: "CLINIC_SELECTION",
    selectedClinic,
  };
}

export function getSymptomsList() {
  let config: AxiosRequestConfig = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API}/covid/symptom_list/`,
  };

  return (dispatch: Dispatch<any>) => {
    dispatch(requestSymptomsList());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveSymptomsList(data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(errorSymptomsList());
      });
  };
}

export function selectSymptom(symptomPk: number) {
  return {
    type: "SELECT_SYMPTOM",
    symptomPk,
  };
}

export function removeSymptom(symptomPk: number) {
  return {
    type: "REMOVE_SYMPTOM",
    symptomPk,
  };
}

export function setSymptomStart(start: string | null) {
  return {
    type: "SET_SYMPTOM_START",
    start,
  };
}

export function setInContact(contact: string) {
  return {
    type: "SET_IN_CONTACT",
    contact,
  };
}

export function setInContactDescription(description: string) {
  return {
    type: "SET_IN_CONTACT_DESCRIPTION",
    description,
  };
}

export function setAppointmentDate(date: string) {
  return {
    type: "SET_DATE",
    date,
  };
}

export function savePatientInfo(patientInfo: any) {
  return {
    type: "SET_PATIENT_INFO",
    patientInfo,
  };
}

function requestSubmitAllData() {
  return {
    type: "REQUEST_SUBMIT_ALL_DATA",
    isSubmittingAllData: true,
  };
}

function receiveSubmitAllData() {
  return {
    type: "RECEIVE_SUBMIT_ALL_DATA",
    isSubmittingAllData: false,
    hasSubmittedData: true,
  };
}

export function createUserAndProfile(userInfo: any, officePk: number) {
  let config: AxiosRequestConfig = {
    method: "post",
    url: `${process.env.REACT_APP_DEV_API}/signup/`,
    data: {
      email: userInfo.email,
      password: userInfo.password,
      is_patient: true,
      office: [officePk],
      patient_info: {
        first_name: userInfo.first_name,
        last_name: userInfo.last_name,
        gender: userInfo.gender,
        date_of_birth: moment(userInfo.date_of_birth).format("YYYY-MM-DD"),
        phone: userInfo.phone,
        ohip_num: userInfo.ohip_num,
        ohip_vc: userInfo.ohip_vc,
        address: userInfo.address,
        postal_code: userInfo.postal_code,
        doctor: userInfo.doctor,
        city: userInfo.city,
      },
    },
  };

  return (dispatch: Dispatch<any>) => {
    dispatch(requestCreateUser());

    let schema = yup.object().shape({
      first_name: yup.string().min(1, "Must have First Name").required(),
      last_name: yup.string().required().min(1, "Must have Last Name"),
      phone: yup.string().required(),
      email: yup.string().email(),
      password: yup.string().min(6, "Password must be 6 or more characters"),
      repeatPassword: yup
        .string()
        .oneOf([yup.ref("password")])
        .required("Password confirmation is required."),
      date_of_birth: yup.string().required(),
      address: yup.string().required(),
      postal_code: yup.string().required(),
      city: yup.string().required(),
    });

    schema
      .validate(userInfo)
      .then((valid) => {
        console.log(valid);
        console.log(userInfo);
        console.log("VALID");
        return axios(config)
          .then((res) => res.data)
          .then((data) => {
            dispatch(receiveCreateUser(data.pk));

            dispatch(readTermsAndConditions(data.pk));
          })
          .catch((err) => {
            console.log(err.response);
            if (err.response.data.patient_info) {
              if (err.response.data.patient_info.date_of_birth) {
                dispatch(
                  errorCreateUser(err.response.data.patient_info.date_of_birth)
                );
              } else if (err.response.data.patient_info.postal_code) {
                dispatch(
                  errorCreateUser(
                    `Postal Code: ${err.response.data.patient_info.postal_code}`
                  )
                );
              }
            } else {
              dispatch(
                errorCreateUser(`A field hasn't been entered correctly.`)
              );
            }
            // dispatch(errorCreateUser(`A field hasn't been entered correctly.`));
          })
          .catch((err) => {
            dispatch(
              errorCreateUser(
                "There was an error submitting your form. Make sure all fields are inserted correctly."
              )
            );
          });
      })
      .catch((err) => {
        console.log(err);
        dispatch(errorCreateUser(`${err.errors[0]}`));
      });
  };
}

export function readTermsAndConditions(userPk: number) {
  let config: AxiosRequestConfig = {
    method: "POST",
    url: `${process.env.REACT_APP_DEV_API}/accepted_terms/`,
    data: {
      user: userPk,
      terms_and_condition: 1.0,
    },
  };

  return (dispatch: Dispatch<any>) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => console.log(data));
  };
}

// Create User
// Create Create Appointment
// Create COVID Info
// Create COVID Symptoms
export function createUser(
  userInfo: any,
  appointmentInfo: any,
  officePk: number,
  symptoms: Array<number | null>
) {
  let config: AxiosRequestConfig = {
    method: "post",
    url: `${process.env.REACT_APP_DEV_API}/signup/`,
    data: {
      email: userInfo.email,
      password: userInfo.password,
      is_patient: true,
      office: [officePk],
      patient_info: {
        first_name: userInfo.first_name,
        last_name: userInfo.last_name,
        gender: "male",
        date_of_birth: moment(userInfo.date_of_birth).format("YYYY-MM-DD"),
        phone: userInfo.phone,
        ohip_num: userInfo.ohip_num,
        ohip_vc: userInfo.ohip_vc,
        address: userInfo.address,
      },
    },
  };

  return (dispatch: Dispatch<any>) => {
    dispatch(requestSubmitAllData());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(
          createAppointment(appointmentInfo, data.pk, officePk, symptoms)
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(errorSubmittingData(err.response));
      });
  };
}

export function createAppointment(
  formData: any,
  userPk: number,
  officePk: number,
  symptoms: Array<number | null>
) {
  console.log(formData);
  let config: AxiosRequestConfig = {
    method: "post",
    url: `${process.env.REACT_APP_DEV_API}/appointments/appointment_create/`,
    data: {
      event_date: formData.date,
      event_time: moment(formData.time, "LT").format("HH:mm"),
      office: officePk,
      user: userPk,
      booked_at: "patient",
    },
  };

  return (dispatch: Dispatch<any>) => {
    dispatch(requestSubmitAllData());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(createCovidInfo(data.pk, formData, symptoms));
      })
      .catch((err) => {
        dispatch(errorSubmittingData(err.response));
        console.log(err);
      });
  };
}

export function createCovidInfo(
  appointmentPk: number,
  formData: any,
  symptoms: Array<number | null>
) {
  let config: AxiosRequestConfig = {
    method: "POST",
    url: `${process.env.REACT_APP_DEV_API}/covid/covid_info/`,
    data: {
      appointment: appointmentPk,
      sympton_startedfrom: formData.start,
      contactedwith_covid: formData.contact,
      contactedwith_description: formData.description,
    },
  };

  return (dispatch: Dispatch<any>) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(createCovidSymptoms(symptoms, data.id, appointmentPk));
      })
      .catch((err) => {
        dispatch(errorSubmittingData(err.response));
      });
  };
}

export function createCovidSymptoms(
  symptoms: Array<number | null>,
  covidInfoPk: number,
  appointmentPk: number
) {
  let config: AxiosRequestConfig = {
    method: "POST",
    url: `${process.env.REACT_APP_DEV_API}/covid/user_symptom_create/`,
    data: symptoms.map((symptom) => ({
      covid_info: covidInfoPk,
      symptom,
    })),
  };

  return (dispatch: Dispatch<any>) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(createExportFile(appointmentPk));
      })
      .catch((err) => {
        dispatch(errorSubmittingData(err.response));
      });
  };
}

export function createExportFile(appointmentPk: number) {
  let config: AxiosRequestConfig = {
    method: "POST",
    url: `${process.env.REACT_APP_DEV_API}/appointments/save_to_file/`,
    data: {
      appointmentPk,
    },
  };

  return (dispatch: Dispatch<any>) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveSubmitAllData());
      })
      .catch((err) => {
        dispatch(errorSubmittingData(err.response));
      });
  };
}

export function getNextThreeDays() {
  let config: AxiosRequestConfig = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API}/appointments/appointment_nextthree`,
  };

  return (dispatch: Dispatch<any>) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        console.log(data);
      });
  };
}

export function getFirstDay(officePk: number, date: string) {
  let config: AxiosRequestConfig = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API}/appointments/appointment_datetimes/?office=${officePk}&date=${date}`,
  };

  return (dispatch: Dispatch<any>) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveFirstTimes(data));
      });
  };
}

export function getSecondDay(officePk: number, date: string) {
  let config: AxiosRequestConfig = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API}/appointments/appointment_datetimes/?office=${officePk}&date=${date}`,
  };

  return (dispatch: Dispatch<any>) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveSecondTimes(data));
      });
  };
}

export function getThirdDay(officePk: number, date: string) {
  let config: AxiosRequestConfig = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API}/appointments/appointment_datetimes/?office=${officePk}&date=${date}`,
  };

  return (dispatch: Dispatch<any>) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveThirdTimes(data));
      });
  };
}

export function checkEmail(email: string) {
  let config: AxiosRequestConfig = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API}/check-email/${email}/`,
  };

  return (dispatch: any) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => dispatch(receiveIsNewUser(false)))
      .catch((err) => dispatch(receiveIsNewUser(true)));
  };
}

export function getTimesFromDate(officePk: number, date: string) {
  let config: AxiosRequestConfig = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API}/appointments/appointment_datetimes/?office=${officePk}&date=${date}`,
  };

  return (dispatch: Dispatch<any>) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        console.log(data);
        dispatch(receiveTimes(data));
      });
  };
}

export function checkIfStillAvailable(
  date: string,
  time: string,
  office: number
) {
  let config: AxiosRequestConfig = {
    method: "POST",
    url: `${process.env.REACT_APP_DEV_API}/appointments/validate_appointment_time/`,
    data: {
      appointment_date: date,
      appointment_time: time,
      office,
    },
  };

  return (dispatch: Dispatch<any>) => {
    dispatch(requestCheckIfStillAvailable());
    return axios(config)
      .then((res) => {
        dispatch(receiveCheckIfStillAvailable());
      })
      .catch((err) => {
        dispatch(errorCheckIfStillAvailable());
        dispatch(resetDateAndTime());
      });
  };
}

export function finalCheckIfStillAvailable(
  formData: any,
  userPk: number,
  officePk: number,
  symptoms: Array<number | null>
) {
  let config: AxiosRequestConfig = {
    method: "POST",
    url: `${process.env.REACT_APP_DEV_API}/appointments/validate_appointment_time/`,
    data: {
      appointment_date: formData.date,
      appointment_time: formData.time,
      office: officePk,
    },
  };

  return (dispatch: Dispatch<any>) => {
    dispatch(requestSubmitAllData());
    dispatch(requestCheckIfStillAvailable());
    return axios(config)
      .then((res) => {
        dispatch(receiveCheckIfStillAvailable());
        dispatch(createAppointment(formData, userPk, officePk, symptoms));
      })
      .catch((err) => {
        dispatch(errorCheckIfStillAvailable());
        dispatch(resetDateAndTime());
      });
  };
}
