import React, { useEffect } from "react";
import { useHistory, Redirect, Link } from "react-router-dom";

import cn from "classnames";
import Progress from "../Progress/Progress";
import ArrowIcon from "../../assets/icons/ArrowIcon";
import s from "./Footer.module.scss";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import {
  createUser,
  createUserAndProfile,
  createAppointment,
  checkIfStillAvailable,
  finalCheckIfStillAvailable,
  setModal,
} from "../../actions/covidActions";
// import { submitRequest } from "../../actions/covidActions";
import { BounceLoader } from "react-spinners";
import Modal from "react-modal";
import InputMask from "react-input-mask";
import PrivacyPolicy from "./PrivacyPolicy";

type PropsType = {
  nextLink: string;
  prevLinkTitle?: string | undefined;
  nextLinkTitle: string;
  isAuthorized: boolean;
  progressLevel: number;
  isSubmitting?: boolean;
  hideNextStep?: boolean | null;
  saveProfile?: boolean | null;
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    maxHeight: "80vh",
    maxWidth: "70vw",
    marginRight: "-50%",
    overflow: "auto",
    transform: "translate(-50%, -50%)",
  },
};

const Footer = ({
  nextLink,
  nextLinkTitle,
  prevLinkTitle,
  isAuthorized,
  progressLevel,
  isSubmitting,
  hideNextStep,
  saveProfile,
}: PropsType): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();

  const profileData = useSelector(
    (state: RootStateOrAny) => state.mainReducer.profileData
  );
  const isCreatingUser = useSelector(
    (state: RootStateOrAny) => state.mainReducer.isCreatingUser
  );
  const date = useSelector((state: RootStateOrAny) => state.mainReducer.date);
  const time = useSelector((state: RootStateOrAny) => state.mainReducer.time);
  const start = useSelector((state: RootStateOrAny) => state.mainReducer.start);
  const contact = useSelector(
    (state: RootStateOrAny) => state.mainReducer.contact
  );
  const modal = useSelector((state: RootStateOrAny) => state.mainReducer.modal);
  const description = useSelector(
    (state: RootStateOrAny) => state.mainReducer.description
  );
  const selectedClinic = useSelector(
    (state: RootStateOrAny) => state.mainReducer.selectedClinic
  );
  const selectedSymptoms = useSelector(
    (state: RootStateOrAny) => state.mainReducer.selectedSymptoms
  );
  const isSubmittingAllData = useSelector(
    (state: RootStateOrAny) => state.mainReducer.isSubmittingAllData
  );
  const hasSubmittedData = useSelector(
    (state: RootStateOrAny) => state.mainReducer.hasSubmittedData
  );
  const userPk = useSelector(
    (state: RootStateOrAny) => state.mainReducer.userPk
  );
  const createUserError = useSelector(
    (state: RootStateOrAny) => state.mainReducer.createUserError
  );

  const isAvailable = useSelector(
    (state: RootStateOrAny) => state.mainReducer.isAvailable
  );

  useEffect(() => {
    if (date && time) {
      const getAppointmentIntervals = setInterval(() => {
        dispatch(checkIfStillAvailable(date, time, selectedClinic.pk));
      }, 5000);

      return () => clearInterval(getAppointmentIntervals);
    }
  }, []);

  return (
    <footer className={s.footer}>
      <Modal
        isOpen={modal}
        style={customStyles}
        onRequestClose={() => dispatch(setModal(false))}
      >
        <PrivacyPolicy />
        <button
          className="primary-btn"
          style={{
            float: "left",
            maxWidth: "100px",
            marginRight: "20px",
            fontSize: "16px",
            height: "50px",
          }}
          onClick={(e) => {
            e.preventDefault();
            dispatch(setModal(false));
          }}
        >
          Cancel
        </button>
        <button
          className="primary-btn confirm-button"
          style={{
            float: "left",
            maxWidth: "100px",
            fontSize: "16px",
            height: "50px",
          }}
          onClick={(e) => {
            e.preventDefault();
            dispatch(createUserAndProfile(profileData, selectedClinic.pk));
            dispatch(setModal(false));
          }}
        >
          I Accept
        </button>
        q
      </Modal>
      {isAvailable == false && (
        <Link to={`/options`}>
          <p
            style={{ color: "red", textAlign: "center", marginBottom: "20px" }}
          >
            We are having larger than expected bookings. Your selected date and
            time has full been booked.{" "}
            <span style={{ textDecoration: "underline" }}>
              Click here to choose another time.
            </span>
          </p>
        </Link>
      )}
      <Progress
        isAuthorized={false}
        mobile
        progressLevel={progressLevel}
        className={s.footer__progress_mobile}
      />
      <div className="container">
        <div className={s.footer__linksWrap}>
          {prevLinkTitle && (
            <button
              className={s.footer__prevPageLink}
              type="button"
              onClick={() => history.goBack()}
            >
              <ArrowIcon
                direction="left"
                fill="#38417c"
                className={s.footer__prevPageIcon}
              />
              {prevLinkTitle}
            </button>
          )}
          {saveProfile ? (
            !isCreatingUser ? (
              <>
                {createUserError && (
                  <p style={{ color: "red" }}>{createUserError}</p>
                )}
                <button
                  className={s.footer__nextPageLink}
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(setModal(true));
                  }}
                >
                  Save Profile
                  <ArrowIcon
                    direction="right"
                    fill="#ffffff"
                    className={s.footer__nextPageIcon}
                  />
                </button>
              </>
            ) : (
              <BounceLoader />
            )
          ) : !isSubmitting ? (
            hideNextStep || hideNextStep == null ? (
              <Link to={nextLink} className={s.footer__nextPageLink}>
                {nextLinkTitle}
                <ArrowIcon
                  direction="right"
                  fill="#ffffff"
                  className={s.footer__nextPageIcon}
                />
              </Link>
            ) : (
              ""
            )
          ) : isAvailable === false ? (
            <div>
              <Link to={`/covid/options`}>
                <button
                  className={s.footer__nextPageLink}
                  style={{ opacity: 0.3, cursor: "not-allowed" }}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  Submit Request Now
                  <ArrowIcon
                    direction="right"
                    fill="#ffffff"
                    className={s.footer__nextPageIcon}
                  />
                </button>
              </Link>
            </div>
          ) : !isSubmittingAllData ? (
            <button
              className={s.footer__nextPageLink}
              onClick={(e) => {
                e.preventDefault();
                dispatch(
                  finalCheckIfStillAvailable(
                    {
                      date,
                      time,
                      start,
                      contact,
                      description,
                    },
                    userPk,
                    selectedClinic.pk,
                    selectedSymptoms
                  )
                );
                // dispatch(
                //   createAppointment(
                //     {
                //       date,
                //       time,
                //       start,
                //       contact,
                //       description,
                //     },
                //     userPk,
                //     selectedClinic.pk,
                //     selectedSymptoms
                //   )
                // );
              }}
            >
              Submit Request Now
              <ArrowIcon
                direction="right"
                fill="#ffffff"
                className={s.footer__nextPageIcon}
              />
            </button>
          ) : (
            <BounceLoader />
          )}
        </div>
        <Progress
          progressLevel={progressLevel}
          isAuthorized={isAuthorized}
          className={s.footer__progress_desktop}
        />
        {hasSubmittedData && <Redirect to="/confirmation" />}
      </div>
    </footer>
  );
};

export default Footer;
