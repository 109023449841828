const initialState = {
  isFetchingCoordinates: false,
  coordinates: null,
  placeName: "",
  isSearchingLocations: false,
  locations: null,
  isFetchingClinics: false,
  clinics: null,
  selectedClinic: null,
  isFetchingSymptoms: false,
  symptoms: [],
  selectedSymptoms: [],
  start: null,
  date: null,
  time: null,
  newUser: null,
  profileData: {},
  isSubmittingAllData: false,
  hasSubmittedData: false,
  profileComplete: false,
  threeDays: [],
  firstTimes: [],
  secondTimes: [],
  thirdTimes: [],
  times: [],
  submittingAllDataError: "",
  isCreatingUser: false,
  createUserError: "",
  userPk: null,
  hasCreatedUser: false,
  contact: "",
  description: "",
  checkingAvailability: false,
  isAvailable: true,
  modal: false,
};

const mainReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_MODAL":
      return Object.assign({}, state, {
        modal: action.modal,
      });
    case "RESET_DATE_AND_TIME":
      return Object.assign({}, state, {
        date: null,
        time: null,
      });
    case "REQUEST_CHECK_IF_STILL_AVAILABLE":
      return Object.assign({}, state, {
        checkingAvailability: true,
      });
    case "RECEIVE_CHECK_IF_STILL_AVAILABLE":
      return Object.assign({}, state, {
        checkingAvailability: false,
        isAvailable: true,
      });
    case "ERROR_CHECK_IF_STILL_AVAILABLE":
      return Object.assign({}, state, {
        checkingAvailability: false,
        isAvailable: false,
      });
    case "ERROR_SUBMITTING_DATA":
      return Object.assign({}, state, {
        submittingAllDataError: action.submittingAllDataError,
        isSubmittingAllData: false,
      });
    case "RECEIVE_TIMES":
      return Object.assign({}, state, {
        times: action.times,
      });
    case "REQUEST_SUBMIT_ALL_DATA":
      return Object.assign({}, state, {
        isSubmittingAllData: action.isSubmittingAllData,
      });
    case "RECEIVE_FIRST_TIMES":
      return Object.assign({}, state, {
        firstTimes: action.firstTimes,
      });
    case "RECEIVE_SECOND_TIMES":
      return Object.assign({}, state, {
        secondTimes: action.secondTimes,
      });
    case "RECEIVE_THIRD_TIMES":
      return Object.assign({}, state, {
        thirdTimes: action.thirdTimes,
      });
    case "SET_NEXT_THREE_DAYS":
      return Object.assign({}, state, {
        threeDays: action.threeDays,
      });
    case "RECEIVE_SUBMIT_ALL_DATA":
      return Object.assign({}, state, {
        isSubmittingAllData: action.isSubmittingAllData,
        hasSubmittedData: action.hasSubmittedData,
      });
    case "RECEIVE_IS_NEW_USER":
      return Object.assign({}, state, {
        newUser: action.newUser,
      });
    case "SET_PROFILE_DATA":
      return Object.assign({}, state, {
        profileData: action.profileData,
        profileComplete: true,
      });
    case "REQUEST_GET_COORDINATES":
      return Object.assign({}, state, {
        isFetchingCoordinates: true,
      });
    case "RECEIVE_GET_COORDINATES":
      return Object.assign({}, state, {
        isFetchingCoordinates: false,
        coordinates: action.coordinates,
        placeName: action.placeName,
      });
    case "ERROR_GET_COORDINATES":
      return Object.assign({}, state, {
        isFetchingCoordinates: false,
        getCoordinatesError: action.getCoordinatesError,
      });
    case "REQUEST_SEARCH_LOCATION":
      return Object.assign({}, state, {
        isSearchingLocations: true,
      });
    case "RECEIVE_SEARCH_LOCATION":
      return Object.assign({}, state, {
        isSearchingLocations: false,
        locations: action.locations,
      });
    case "ERROR_SEARCH_LOCATION":
      return Object.assign({}, state, {
        isSearchingLocations: false,
        searchError: action.searchError,
      });
    case "REQUEST_GET_CLINICS":
      return Object.assign({}, state, {
        isFetchingClinics: true,
      });
    case "RECEIVE_GET_CLINICS":
      return Object.assign({}, state, {
        isFetchingClinics: false,
        clinics: action.clinics,
      });
    case "CLINIC_SELECTION":
      return Object.assign({}, state, {
        selectedClinic: action.selectedClinic,
      });
    case "REQUEST_SYMPTOMS_LIST":
      return Object.assign({}, state, {
        isFetchingSymptoms: true,
      });
    case "RECEIVE_SYMPTOMS_LIST":
      return Object.assign({}, state, {
        isFetchingSymptoms: false,
        symptoms: action.symptoms,
      });
    case "ERROR_SYMPTOMS_LIST":
      return Object.assign({}, state, {
        isFetchingSymptoms: false,
      });
    case "SELECT_SYMPTOM":
      return Object.assign({}, state, {
        selectedSymptoms: [...state.selectedSymptoms, action.symptomPk],
      });
    case "REMOVE_SYMPTOM":
      return Object.assign({}, state, {
        selectedSymptoms: state.selectedSymptoms.filter(
          (symptom: number) => symptom !== action.symptomPk
        ),
      });
    case "SET_SYMPTOM_START":
      return Object.assign({}, state, {
        start: action.start,
      });
    case "SET_DATE":
      return Object.assign({}, state, {
        date: action.date,
      });
    case "SET_APPOINTMENT_TIME":
      return Object.assign({}, state, {
        time: action.time,
      });
    case "SET_IN_CONTACT":
      return Object.assign({}, state, {
        contact: action.contact,
      });
    case "SET_IN_CONTACT_DESCRIPTION":
      return Object.assign({}, state, {
        description: action.description,
      });
    case "REQUEST_CREATE_USER":
      return Object.assign({}, state, {
        isCreatingUser: true,
        createUserError: "",
      });
    case "RECEIVE_CREATE_USER":
      return Object.assign({}, state, {
        isCreatingUser: false,
        userPk: action.userPk,
        hasCreatedUser: true,
      });
    case "ERROR_CREATE_USER":
      return Object.assign({}, state, {
        isCreatingUser: false,
        createUserError: action.createUserError,
      });
    default:
      return state;
  }
};

export default mainReducer;
