import HomePage from "../pages/HomePage";
import SearchPage from "../pages/SearchPage";
import OptionsPage from "../pages/OptionsPage";
import ProfilePage from "../pages/ProfilePage";
import SymptomsListPage from "../pages/SymptomsListPage";
import SymptomsTimelinePage from "../pages/SymptomsTimelinePage";
import InContactPage from "../pages/InContactPage";
import ConfirmationPage from "../pages/ConfirmationPage";

export default {
  HOME_PAGE: {
    path: "/",
    component: SearchPage,
  },
  SEARCH_PAGE: {
    path: "/search",
    component: SearchPage,
  },
  OPTIONS_PAGE: {
    path: "/options",
    component: OptionsPage,
  },
  PROFILE_PAGE: {
    path: "/profile",
    component: ProfilePage,
  },
  SYMPTOMS_LIST_PAGE: {
    path: "/symptoms-list",
    component: SymptomsListPage,
  },
  SYMPTOMS_TIMELINE_PAGE: {
    path: "/symptoms-timeline",
    component: SymptomsTimelinePage,
  },
  IN_CONTACT_PAGE: {
    path: "/in-contact",
    component: InContactPage,
  },
  CONFIRMATION_PAGE: {
    path: "/confirmation",
    component: ConfirmationPage,
  },
};
