import React, { useEffect } from "react";
import MainLayout from "./layouts/MainLayout/MainLayout";
import { useHistory, Redirect } from "react-router-dom";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

const App: React.FC = () => {
  const history = useHistory();

  const placeName = useSelector(
    (state: RootStateOrAny) => state.mainReducer.placeName
  );
  const selectedClinic = useSelector(
    (state: RootStateOrAny) => state.mainReducer.selectedClinic
  );

  useEffect(() => {
    if (!selectedClinic) {
      history.push("/");
    }
  }, []);

  return (
    <div className="App">
      <MainLayout />
    </div>
  );
};

export default App;
